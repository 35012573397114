import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';

import {
  MapboxPlacesResult,
  MapboxPlacesResultTranslated,
} from '@/app/api/mapbox-places/types';
import { CustomOption } from '@/components/form/CustomSelect/types';
import {
  LocationPhases,
  SimpleLocationDto,
  SimpleLocationSearchDto,
} from '@/lib/locations/dto';
import { SearchMeetingRoomsPricesDto } from '@/lib/meeting-rooms/dto/search-meeting-rooms-prices.dto';
import { SimpleMeetingRoomDto } from '@/lib/meeting-rooms/dto/simple-meeting-room.dto';

import { isExternalLocation, isIndustriousLocation } from './locations';

export type LocationMapFeatureProps = Pick<
  SimpleLocationSearchDto,
  'id' | 'slug' | 'name' | 'address' | 'city' | 'zipCode' | 'phone'
> & {
  imageUrl?: string;
  imageAltText?: string | null;
  lat: number;
  lng: number;
  external?: boolean;
  externalLink?: string;
  state: string | null;
  phase?: LocationPhases;
};

export type MeetingRoomMapFeatureProps = Pick<
  SimpleMeetingRoomDto,
  | 'slug'
  | 'name'
  | 'locationName'
  | 'seats'
  | 'amenities'
  | 'currencyIsoCode'
  | 'city'
  | 'locationTier'
> & {
  imageUrl?: string;
  imageAltText?: string | null;
  prices?: SearchMeetingRoomsPricesDto['key'];
  lat: number;
  lng: number;
};

export function getMapboxFeaturesLocations(
  locations: (SimpleLocationSearchDto | SimpleLocationDto)[]
): Feature<Geometry, LocationMapFeatureProps>[] {
  return locations
    .filter(
      (loc) =>
        (isIndustriousLocation(loc) && !!loc.slug) || !!loc.geolocalization
    )
    .map((loc) => ({
      type: 'Feature',
      properties: {
        id: loc.id,
        slug: loc.slug,
        imageUrl: loc.image?.url,
        imageAltText: loc.image?.altText,
        name: loc.name,
        address: loc.address,
        city: loc.city,
        state: isIndustriousLocation(loc) ? loc.state : null,
        zipCode: loc.zipCode,
        phone: loc.phone,
        lat: loc.geolocalization.lat,
        lng: loc.geolocalization.lng,
        isExternal: loc.isExternal,
        externalLink: isExternalLocation(loc) ? loc.externalLink : undefined,
        phase: isIndustriousLocation(loc) ? loc.phase : undefined,
      },
      geometry: {
        type: 'Point',
        coordinates: [loc.geolocalization.lng, loc.geolocalization.lat],
      },
    }));
}

export function getMapboxFeaturesMeetingRooms(
  meetingRooms: SimpleMeetingRoomDto[],
  prices?: SearchMeetingRoomsPricesDto
): Feature<Geometry, MeetingRoomMapFeatureProps>[] {
  return meetingRooms.map((room) => ({
    type: 'Feature',
    properties: {
      slug: room.slug,
      name: room.name,
      seats: room.seats,
      amenities: room.amenities,
      locationName: room.locationName,
      imageUrl: room.image?.url,
      imageAltText: room.image?.altText,
      prices: prices?.[room.slug] || undefined,
      lat: room.geolocalization.lat,
      lng: room.geolocalization.lng,
      currencyIsoCode: room.currencyIsoCode,
      city: room.city,
    },
    geometry: {
      type: 'Point',
      coordinates: [room.geolocalization.lng, room.geolocalization.lat],
    },
  }));
}

// Because features come from tiled vector data, feature geometries may be split
// or duplicated across tile boundaries. As a result, features may appear multiple times in query results.
export function getUniqueFeatures(
  features: Feature<Geometry, GeoJsonProperties>[]
): Feature<Geometry, GeoJsonProperties>[] {
  const uniqueIds = new Set();
  const uniqueFeatures = [];
  for (const feature of features) {
    const id = feature.id || feature.properties?.slug;
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueFeatures.push(feature);
    }
  }
  return uniqueFeatures;
}

function getClusterLeavesOfSource(
  cluster_id: number,
  limit: number,
  offset: number,
  source: mapboxgl.GeoJSONSource
): Promise<Feature<Geometry, GeoJsonProperties>[]> {
  return new Promise((resolve, reject) => {
    source.getClusterLeaves(cluster_id, limit, offset, (err, features) => {
      if (err) reject(err);
      else resolve(features);
    });
  });
}

export const getArrayOfKeyRenderedFeaturesWithinView = async (
  source: mapboxgl.GeoJSONSource,
  renderedFeatures: Feature<Geometry, GeoJsonProperties>[],
  key: string
) => {
  let arrayOfKeyRendered: string[] = [];
  for await (const feature of renderedFeatures) {
    const props = feature.properties!;
    const isACluster = props.cluster;
    if (isACluster) {
      const aFeatures = await getClusterLeavesOfSource(
        props.cluster_id,
        props.point_count,
        0,
        source
      );
      const allInCluster = aFeatures.map((f) => f.properties![key]);
      arrayOfKeyRendered = [...arrayOfKeyRendered, ...allInCluster];
    } else {
      arrayOfKeyRendered = [...arrayOfKeyRendered, props[key]];
    }
  }
  return arrayOfKeyRendered;
};

export function getCenterFromFeatures(
  featuresLocations: Feature<Geometry, GeoJsonProperties>[]
) {
  const bounds = new mapboxgl.LngLatBounds();

  for (const feature of featuresLocations) {
    if (feature.geometry.type === 'Point') {
      bounds.extend(feature.geometry.coordinates as [number, number]);
    }
  }

  return bounds.getCenter();
}

export const getPlaceNameArrayFromFeature = (
  feature: MapboxPlacesResult,
  locale: string
) => {
  const name =
    feature[`place_name_${locale}` as keyof MapboxPlacesResultTranslated] ||
    feature.place_name;
  const placeName = name.split(',');

  return placeName;
};

export const mapResultsToOptions = (
  results: MapboxPlacesResult[],
  locale: string
): CustomOption[] =>
  results.map((feat) => {
    const placeNameArray = getPlaceNameArrayFromFeature(feat, locale);
    return {
      label: placeNameArray.shift() as string,
      description: placeNameArray.join(','),
      value: feat.id as string,
    };
  });
